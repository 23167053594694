import React from 'react'
import { Link, useStaticQuery, graphql} from 'gatsby'
import { Layout, Parallax, DelayedLink} from '@common'
import {
	page__wrapper,
	page__quote,
	page__parallax,
	page__parallax__inner,
	page__buttons,
	page__button,
	page__button__alt,
	page__testimonial,
	page__list,
	page__grid,
	page__grid__row,
} from './styles/page.module.scss'


const WhyWorth = ({ pageContext, location }) => {
	const { WP } = useStaticQuery(graphql`
	query {
		WP {
			mediaItemBy(slug: "markus-spiske-1LLh8k2_YFk-unsplash") {
				sourceUrl
				srcSet
				altText
			}
		}
	}
`)
	
	return(
	<Layout
		location={location}
		page={pageContext.page}

	>
		<div className={page__wrapper}>

		<div className={page__parallax}>
			<Parallax height={400} image={WP.mediaItemBy}>
				<div className={page__parallax__inner}>
					<h1>
						Dlaczego to robię?
					</h1>
				</div>
			</Parallax>
		</div>
		<h1>
			<strong>Moją pasją</strong> jest zarażanie innych pasją do programowania
		</h1>
		<h3>
			Tworzę ogromny, <strong>kompleksowy zbiór rozwijających zadań z dziedziny frontendu </strong>
			dla każdego po dowolnym kursie
		</h3>
		<h1>
			Abyś mógł / mogła znaleźć zadania dopasowane <strong>do swojego poziomu</strong>
		</h1>
		<h1>
			Abyś mógł / mogła dalej ćwiczyć <strong>ciągle szlifując swoje umiejętności</strong>
		</h1>
		<ul className={page__grid}>
			<li>
				<h4>Czujesz, że masz za mało praktyki?</h4>
				<h6>
					Weź sobie dowolne zadanie z dowolnego zbioru i zrób
				</h6>
			</li>
			<li>
				<h4>Po kursie wiesz niewiele</h4>
				<h6>
					Każde zadanie ze zbioru, z którym się zmierzysz będzie stanowiło dla
					Ciebie wyzwanie oraz rozwiązanie przyniesie Ci ogrom satysfakcji
					oraz podniesie poziom Twoich umiejętności jeden szczebelek
					wyżej. Potem odpocznij i weź kolejne zadanie ze zbioru...
				</h6>
			</li>
			<li>
				<h4>Z życia i pracy wzięte</h4>
				<h6>
					Zadania zostały opracowane tak, aby umożliwić Ci
					przećwiczenie wielu podstawowych i średniozaawansowanych
					zagadnień podobnych do tych, z którymi spotkasz się pracując
					jako Junior Frontend Developer
				</h6>
			</li>
		</ul>
		<h2>Chcę do końca 2020, 2 razy w tygodniu publikując nowe zadania stworzyć <strong>największy zbiór był darmowy</strong>, 
		aby przećwiczenie go zajeło Ci pół roku, bo wg mnie <strong>programowania nie da się nauczyć przez przyglądanie</strong></h2>


		<div className={page__buttons}>
		<div className={`${page__button}`}>
			<span>&gt;</span>
			<DelayedLink to={'/help/'}>Świetna inicjatywa!</DelayedLink>
		</div>
	</div>
	</div>
	</Layout>

)}

export default WhyWorth
